import {
  BreakStyle,
  SVG,
  defineStylesWithBreaks,
  useGLTranslation,
  useThemedStylesWithBreaks
} from 'notability-services-common-frontend'
import {TranslationKey} from 'notability-services-common-universal'

type Props = {
  features: TranslationKey[]
  doEmphasize?: boolean
}

export function SubscriptionFeatureList({features, doEmphasize}: Props) {
  const {t} = useGLTranslation()
  const s = useThemedStylesWithBreaks(styles)

  return (
    <div>
      <div style={s.list}>
        {features.map((f) => (
          <div style={doEmphasize ? s.lineItemEmphasize : s.lineItem} key={f}>
            <SVG.CheckLarge {...s.checkmark} />
            {t(f)}
          </div>
        ))}
      </div>
    </div>
  )
}

const lineItemCommon: BreakStyle = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  fontSize: '22px',
  gap: '8px'
}

const styles = defineStylesWithBreaks((theme, font) => {
  return {
    checkmark: {
      color: theme.accent.base
    },

    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: '17px'
    },

    lineItem: {
      ...font.body,
      ...lineItemCommon
    },

    lineItemEmphasize: {
      ...font.bodyBold,
      ...lineItemCommon
    }
  }
})
