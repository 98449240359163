import {useMemo} from 'react'
import {
  ActionButton,
  AnalyticsParameterName,
  AnalyticsValue,
  BreakStyle,
  convertToAnalyticsString,
  defineStylesWithBreaks,
  GLTranslate,
  themeIsDark,
  useGLTranslation,
  useTheme,
  useThemedStylesWithBreaks
} from 'notability-services-common-frontend'
import {Stripe} from 'stripe'
import {proxima} from '../fonts'
import {SubscriptionFeatureList} from './SubscriptionFeatureList'
import {addingBasePath} from '../config'
import {useCustomRouter} from '../util/useCustomRouter'

const formatCurency = (amount: number, currency: string, omitCentsIfZero: boolean = true) => {
  const dollars = Math.floor(amount / 100)
  const cents = amount - dollars * 100
  const numberString = `${cents === 0 && omitCentsIfZero ? dollars : `${dollars}.${cents}`}`

  if (currency === 'usd') {
    return `$${numberString}`
  } else {
    return `${numberString}${currency}`
  }
}

interface Props {
  price: Stripe.Price
}

export function getStripePriceAnalyticsParams(
  price: Stripe.Price
): Partial<Record<AnalyticsParameterName, AnalyticsValue>> {
  const metadata = Object.entries(price.metadata).reduce((acc, [key, value]) => {
    acc[convertToAnalyticsString(key)] = convertToAnalyticsString(value)
    return acc
  }, {} as Record<string, string>)
  return {
    ...metadata,
    'Stripe Price ID': price.id,
    'Subscription Interval': price.recurring
      ? convertToAnalyticsString(`${price.recurring.interval_count} ${price.recurring.interval}`)
      : undefined,
    'Subscription Price': price.unit_amount ?? undefined
  }
}

export function StarterTierCard() {
  const s = useThemedStylesWithBreaks(styles)
  const {t} = useGLTranslation()

  return (
    <div style={s.starterCard}>
      <div style={s.starterCardContent}>
        <div style={s.planTitle}>{t('consumerFreeTitle')}</div>
        <div style={s.price}>{t('pricingFreeSubHeader')}</div>
        <SubscriptionFeatureList
          features={['consumerFeatureUnlimitedNotes', 'consumerFeaturePdfAnnotation', 'consumerFeatureRecordAudio']}
        />
      </div>
      <div style={s.currentPlanContainer}>{t('consumerCurrentPlan')}</div>
    </div>
  )
}

export function SubscriptionCard({price}: Props) {
  const s = useThemedStylesWithBreaks(styles)
  const {t} = useGLTranslation()
  const router = useCustomRouter()
  const formattedPrice = useMemo(
    () => (price.unit_amount ? formatCurency(price.unit_amount, price.currency) : 'unknown'),
    [price]
  )
  const {theme} = useTheme()
  const plusLogo = useMemo(() => {
    return addingBasePath(themeIsDark(theme.name) ? '/plus-dark.svg' : '/plus-light.svg')
  }, [theme])

  return (
    <div style={s.card}>
      <img style={s.plusLogo} alt="plus" src={plusLogo} />
      <div style={s.priceLine}>
        <GLTranslate
          i18nKey={'consumerPricePerYear'}
          values={{
            price: formattedPrice
          }}
        >
          <span style={s.price}>$25</span> / yr
        </GLTranslate>
      </div>
      <SubscriptionFeatureList
        features={['consumerFeatureUnlimitedNotes', 'consumerFeaturePdfAnnotation', 'consumerFeatureRecordAudio']}
      />
      <div style={s.separatorContainer}>
        <span>{t('plusHeading')}</span>
        <hr style={s.separatorBar} />
      </div>
      <SubscriptionFeatureList
        doEmphasize={true}
        features={['consumerFeatureTranscription', 'consumerFeatureLearnSummary', 'consumerFeatureLearnQuizzes']}
      />
      <ActionButton
        style={s.upgradeButton}
        text={t('consumerPlusUpgradeCTA')}
        appearance={'filled'}
        size={'small'}
        onClick={() => {
          router.pushPlanSubscription(price.id)
        }}
      />
    </div>
  )
}

export const commonSubscriptionCardStyles: BreakStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '24px 32px 24px',
  width: '413px',
  border: `0.5px solid`,
  borderRadius: '16px',
  fontFamily: proxima.style.fontFamily,
  maxWidth: 'calc(100vw - 24px)',
  overflow: 'hidden'
}

const styles = defineStylesWithBreaks((theme, font) => {
  return {
    card: {
      ...commonSubscriptionCardStyles,
      ...theme.elevation.level3,
      borderColor: theme.secondary.base
    },

    starterCard: {
      ...commonSubscriptionCardStyles,
      backgroundColor: theme.core.b1,
      borderColor: theme.core.b4
    },

    planTitle: {
      ...font.mediumTitle,
      backgroundColor: theme.core.b2,
      borderRadius: '8px',
      fontSize: '23px',
      color: theme.core.f3,
      margin: '2px 0',
      padding: '8px 16px',
      width: 'fit-content'
    },

    currentPlan: {
      ...font.subheadBold,
      color: theme.core.f2,
      backgroundColor: theme.accent.container,
      padding: '8px',
      borderRadius: '8px',
      border: `1px solid ${theme.accent.containerHover}`,
      width: '100%'
    },

    starterCardContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '1',
      gap: '12px'
    },

    separatorContainer: {
      ...font.smallTitleBold,
      color: theme.secondary.base,
      display: 'flex',
      flexDirection: 'row',
      gap: '12px'
    },

    separatorBar: {
      borderTop: `1px solid ${theme.secondary.base}`,
      height: '1px',
      width: '100%'
    },

    cashCallout: {
      fontSize: '48px'
    },

    plusLogo: {
      height: '54px',
      width: '84px'
    },

    price: {
      ...font.largeTitleBold,
      fontSize: '42px',
      fontWeight: 700
    },

    priceLine: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      gap: '8px'
    },

    upgradeButton: {
      height: '44px',
      marginTop: '28px'
    },

    currentPlanContainer: {
      ...font.bodyBold,
      alignItems: 'center',
      backgroundColor: theme.neutral.container,
      borderRadius: '8px',
      color: theme.neutral.base,
      display: 'flex',
      height: '44px',
      justifyContent: 'center',
      marginTop: '28px',
      padding: '12px 16px'
    }
  }
})
