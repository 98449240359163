import {appSubscriptionCompleteRoute, redirectQueryParam} from 'notability-services-common-universal'
import * as Sentry from '@sentry/react'
import {loginError, marketingHref} from './siteLinks'

import {ParsedUrlQuery} from 'querystring'
import {config} from '../config'
import {useCallback} from 'react'
import {useRouter} from 'next/router'
import {magicLinkTypeParam} from './constants'
import {AuthFlowType} from '../components/LoginForm'
import {useExperimentValues} from './GalleryContext'

/** These are the arguments for the routeAndPreserveQuery function. */
type RouteAndPreserveQueryArgs = {
  /** the path we wish to route to */
  path: string

  /** the keys of the query object we would like to remove. OPTIONAL */
  queryKeysToKeep?: string[]

  /** how we wish to display the path in the browser. OPTIONAL */
  as?: string

  /** Query args that we are adding to the existing ones. */
  newArgs?: Record<string, string>
}

export const planQueryString = 'plan'

/*
  Note to developers: Please do not use <Link/> or useRouter directly!
  Use this wrapper instead, and feel free to extend it to meet your needs.
  When pushing a dynamic route, be _sure_ to use the `as` argument or the router
  will become confused when navigating via browser forward/back commands.
*/
export function useCustomRouter() {
  const router = useRouter()
  const experimentValues = useExperimentValues()

  const push = useCallback(
    (
      url: Parameters<typeof router.push>[0],
      as?: Parameters<typeof router.push>[1],
      options?: Parameters<typeof router.push>[2],
      title?: string
    ) => {
      router.push(url, as, options)
    },
    [router]
  )

  const replace = useCallback(
    (
      url: Parameters<typeof router.push>[0],
      as?: Parameters<typeof router.push>[1],
      options?: Parameters<typeof router.push>[2],
      title?: string
    ) => {
      router.replace(url, as, options)
    },
    [router]
  )

  /**
   * Filters the query object to only keep the keys we specify
   * See root route (pages/index.tsx) for query string keys and their meanings.
   *
   * @param query The queryObject to filter
   * @param filterKeysToKeep the keys of the query we would like to keep. Everything else will be filtered out
   */
  const filterQuery = (query: ParsedUrlQuery, filterKeysToKeep: string[]): ParsedUrlQuery => {
    const keySet = new Set(filterKeysToKeep)
    const filtered: ParsedUrlQuery = {}

    for (const [k, v] of Object.entries(query)) {
      keySet.has(k) && (filtered[k] = v)
    }

    return filtered
  }

  /**
   * This function will route to a desired url and preserve the current query parameters
   * @param args the argument object to pass to this function. Parameters are described in the RouteAndPreserveQueryArgs type definition
   */
  const routeAndPreserveQuery = (args: RouteAndPreserveQueryArgs) => {
    const {path, as, queryKeysToKeep, newArgs} = args
    const existingPairs = Object.entries(filterQuery(router.query, queryKeysToKeep ?? []))
    const newPairs = newArgs ? Object.entries(newArgs) : []
    const paramStrs = [...existingPairs, ...newPairs].reduce((acc, curr) => {
      const [key, value] = curr
      acc.push(`${key}=${value}`)
      return acc
    }, [] as string[])
    let queryStr = paramStrs.join('&')
    if (queryStr !== '') {
      queryStr = `?${queryStr}`
    }

    as ? push(`${path}${queryStr}`, `${as}${queryStr}`) : push(`${path}${queryStr}`)
  }

  return {
    pathname: router.pathname,
    asPath: router.asPath,
    query: router.query,
    events: router.events,
    reload: router.reload,
    // Typescript errors out when returning replace directly with an error regarding the type of `TransitionOptions` so we need to wrap in a function to compile
    replace: (url: string) => replace(url),

    pushRoot({checkForRedirect} = {checkForRedirect: false}) {
      if (checkForRedirect) {
        const redirect = `/${router.query.redirect ?? ''}`
        push(redirect)
      } else {
        routeAndPreserveQuery({path: '/', queryKeysToKeep: ['s', redirectQueryParam]})
      }
    },

    // Signup sometimes has an aliased root (e.g. /get-started), so use the absolute link.
    pushLoginAbsoluteRoute() {
      routeAndPreserveQuery({
        path: config.LOGIN_SERVICE_URL,
        queryKeysToKeep: [redirectQueryParam]
      })
    },

    /** Go back to the homepage, with default sorting/filtering */
    pushDefaultRoot() {
      push('/')
    },

    pushLoginWithError() {
      location.assign(loginError)
    },

    /**
     * Redirects to the homepage.
     */
    redirectToHomepage() {
      replace('/')
    },

    /**
     * After a login, this route will redirect them to the appropriate final destination.
     */
    pushLoginComplete() {
      routeAndPreserveQuery({
        path: '/login-complete',
        queryKeysToKeep: [redirectQueryParam]
      })
    },

    /**
     * Display the page for creating an account via password and email.
     */
    pushCreatePassword(emailAddress?: string) {
      const newArgs = emailAddress && emailAddress.length > 0 ? {email: emailAddress} : undefined
      if (experimentValues.passwordSignupsEnabled) {
        routeAndPreserveQuery({
          path: '/create-password',
          queryKeysToKeep: [redirectQueryParam],
          newArgs
        })
      } else {
        Sentry.captureException(new Error('Attempting to route to create-password, but password signups are disabled.'))
      }
    },

    pushSignup() {
      routeAndPreserveQuery({
        path: '/signup',
        queryKeysToKeep: [redirectQueryParam]
      })
    },

    pushEmailLinkConfirmation(type: AuthFlowType) {
      const newArgs: Record<string, string> = {}
      newArgs[magicLinkTypeParam] = type
      routeAndPreserveQuery({
        path: `/link-sent`,
        queryKeysToKeep: [redirectQueryParam],
        newArgs
      })
    },

    /**
     * Take the user to the Notability Gallery.
     */
    pushGallery() {
      location.assign(config.GALLERY_SERVICE_URL)
    },

    /**
     * Take the user to the Notability Organization Profile.
     */
    pushOrganization() {
      location.assign(config.ORG_SERVICE_URL)
    },

    /**
     * Take them to the notability web app.
     */
    pushApp(onSubscriptionComplete?: boolean) {
      location.assign(onSubscriptionComplete ? `${config.APP_URL}${appSubscriptionCompleteRoute}` : config.APP_URL)
    },

    // Use for local dev HK users
    pushSignout() {
      push('/signout')
    },

    /**
     * Take the user to the Notability Marketing Site.
     */
    pushMarketingSite() {
      location.assign(marketingHref)
    },

    /**
     * Take them to the subscription endpoint.
     */
    pushSubscribe() {
      location.assign(config.SUBSCRIBE_URL)
    },

    pushPlanSubscription(plan: string) {
      const query: Record<string, string> = {}
      query[planQueryString] = plan
      push(
        {
          query,
          pathname: this.pathname
        },
        undefined,
        {
          shallow: true
        }
      )
    }
  }
}

/**
 * Removes the locale from the URL string to provide the base path.
 * @param path URL path from which to remove the locale
 * @param locale Locale string to remove.
 */
export function getBasePath(path: string, locale?: string) {
  const basePath = path.split('?')[0]!
  // If it is the index page, remove locale
  if (basePath.endsWith(`/${locale}`)) {
    return basePath.replace(`/${locale}`, '')
  } else {
    // Else replace with the slash to make sure it does not replace any important information
    return basePath.replace(`/${locale}/`, '/')
  }
}

export function queryParamToString(param: string | string[] | undefined) {
  return typeof param === 'string' ? param : undefined
}
